import { useQuery } from '@apollo/client';
import { Avatar, Tooltip } from 'antd';
import { useState } from 'react';
import { counselviseClient } from '../../apollo';
import { FETCH_USER_QUERY } from '../../app/components/Queries';
import { Close, Hamburger, NoticeBoard } from '../../assets/svg';
import useRouter from '../../hooks/useRouter';
import { HeaderPropsType } from './header.type';
import { generateMenuItems } from './menuOptions';
import { ROUTES } from '../../common/constants';

function Header({ fluid = false }: HeaderPropsType) {
  const {
    location: { pathname },
    navigate,
  } = useRouter();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const redirectProfile = `${process.env.REACT_APP_REDIRECT_PROFILE_URL}/profile`;

  const { data: { currentUser } = {} } = useQuery(FETCH_USER_QUERY, {
    client: counselviseClient,
    fetchPolicy: 'network-only',
  });

  const avatarText = `${currentUser?.firstName?.[0] ?? ''}${currentUser?.lastName?.[0] ?? ''}`;

  const menuItems = generateMenuItems(pathname);

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prev) => {
      document.body.classList.toggle('overflow-hidden', !prev);
      return !prev;
    });
  };

  const handleMenuItemClick = (key: string) => {
    navigate(key);
  };

  return (
    <header>
      <div
        className={`${fluid ? 'container-fluid' : 'container height-full d-flex'}`}
      >
        <div className="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <span onClick={() => navigate(`${ROUTES?.MAIN}`)}>
                <NoticeBoard />
              </span>
            </div>
            <div className="menu-list d-flex height-full m-hide">
              <nav>
                <div className="menu-list-section">
                  <ul className="menu-list-items">
                    {menuItems?.map(({ label, key, icon }) => (
                      <li
                        className={`body-m ${key === pathname || (key?.split('/')?.length > 2 && pathname?.startsWith(key)) ? 'active-tab' : ''}`}
                        key={key}
                        onClick={() => navigate(key)}
                      >
                        <div className="item d-flex align-center gap-8 pointer">
                          {icon}
                          {label}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </nav>
            </div>
            <div
              className={`mobile-menu-list d-hide ${isMobileMenuOpen ? 'open' : ''}`}
            >
              <ul className="menu-sub-list-items">
                {menuItems?.map(({ label, key, icon }) => (
                  <li
                    key={key}
                    onClick={() => handleMenuItemClick(key)}
                    className={`body-m ${key === pathname || (key?.split('/')?.length > 2 && pathname?.startsWith(key)) ? 'active-tab' : ''}`}
                  >
                    <div className="item d-flex align-center gap-8">
                      {icon}
                      {label}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="header-right">
            <ul>
              <li className="m-hide">
                <div
                  className="profile-btn pointer"
                  onClick={() => window.open(redirectProfile)}
                >
                  <Tooltip title="My Profile">
                    {currentUser?.profileImage ? (
                      <Avatar
                        alt={avatarText}
                        src={currentUser?.profileImage}
                      />
                    ) : (
                      <Avatar alt={avatarText}>{avatarText}</Avatar>
                    )}
                  </Tooltip>
                </div>
              </li>
            </ul>
            <div className="d-hide">
              <span onClick={toggleMobileMenu} className="pointer">
                {isMobileMenuOpen ? <Close /> : <Hamburger />}
              </span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
