import { gql } from '../../../__generated__';

const E_PROCEEDINGS_LIST =
  gql(`query EProceedingsList($filter: EProceedingsFilter) {
  eProceedings(filter: $filter) {
    data {
      _id
      assessee {
        username
        name
      }
      assessmentYear
      financialYear
      noticeReferenceId
      name
      issuedOn
      responseDueDate
      proceedingCloseDate
      proceedingCloseOrder
      proceedingLimitationDate
      letterPdf {
        from
        attachments {
          url
        }
      }
      noticeUs
      documentRefId
      description
      proceedingOpenDate
      type
      remarks
      status
    }
    count
  }
}
`);

const E_PROCEEDINGS_DETAILS =
  gql(`query EProceeding($where: EProceedingUniqueInput!) {
    eProceeding(where: $where) {
      _id
      assessee {
        name
        username
      }
      name
      financialYear
      assessmentYear
      documentRefId
      noticeUs
      description
      letterPdf {
      cc
      attachments {
        url
      }
    }
    status
      responses {
        _id
        remarks
        submittedOn
        filedBy
        attachments {
          url
        }
      }
    }
  }
`);

export { E_PROCEEDINGS_DETAILS, E_PROCEEDINGS_LIST };
