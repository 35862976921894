import React from 'react';
import { Space, Typography } from 'antd';

const { Text } = Typography;

type StatisticCardProps = {
  icon: React.ReactNode;
  label: string;
  value: number | string;
  onClick?: () => void;
};

const StatisticCard: React.FC<StatisticCardProps> = ({
  icon,
  label,
  value,
  onClick,
}) => {
  return (
    <Space className="statistic pointer" onClick={onClick}>
      <div className="icon">{icon}</div>
      <div className="status">
        <Text>{label}</Text>
        <Text className="value">{value}</Text>
      </div>
    </Space>
  );
};

export default StatisticCard;
