import { OperationVariables } from '@apollo/client';
import { Select, type GetProps } from 'antd';
import debounce from 'lodash/debounce';
import { useMemo, useState } from 'react';
import useInfiniteQuery, {
  UseInfiniteQueryOptionsType,
  type QueryType,
} from '../hooks/useInfiniteQuery';

type InfiniteSearchType<
  TFilters,
  QueryVariables extends OperationVariables = OperationVariables,
> =
  | {
      allowSearch: true;
      variableSelector: (options: {
        skip: number;
        limit: number;
        search: string;
        filters: TFilters;
      }) => QueryVariables;
    }
  | {
      allowSearch?: false;
      variableSelector: (options: {
        skip: number;
        limit: number;
        filters: TFilters;
      }) => QueryVariables;
    };

type LabelWithValue = { label: string; value: string };

type InfiniteQueryOptionsType<
  TData,
  TFilters,
  QueryData = unknown,
  QueryVariables extends OperationVariables = OperationVariables,
> = {
  query: QueryType<QueryData, QueryVariables>;
  extraOptions?: TData[];
  filterOptions?: (data: TData) => boolean;
} & Omit<
  UseInfiniteQueryOptionsType<TData, TFilters, QueryData, QueryVariables>,
  'variableSelector'
> &
  InfiniteSearchType<TFilters, QueryVariables>;

type InfiniteSelectProps = Omit<
  GetProps<typeof Select>,
  'labelInValue' | 'filterOption' | 'options' | 'showSearch'
>;

const LIMIT = 10;

const InfiniteSelect = <
  TData extends LabelWithValue,
  TFilters,
  QueryData = unknown,
  QueryVariables extends OperationVariables = OperationVariables,
>(
  props: InfiniteSelectProps &
    InfiniteQueryOptionsType<TData, TFilters, QueryData, QueryVariables>,
) => {
  const [search, setSearch] = useState('');

  const {
    query,
    countSelector,
    dataSelector,
    variableSelector: variableSelector$,
    client,
    context,
    filters,
    fetchPolicy,
    initialData,
    limit = LIMIT,
    onCompleted,
    onError,
    skip,
    loading: loadingInput,
    onPopupScroll,
    allowSearch,
    onSearch,
    // Note: extraOptions & filterOptions must be memoized values for better performance
    extraOptions,
    filterOptions,
    ...rest
  } = props;

  const variableSelector = ({
    skip,
    limit,
  }: {
    skip: number;
    limit: number;
  }): QueryVariables => {
    const filters$ = filters as TFilters;
    return allowSearch
      ? variableSelector$({ skip, limit, search, filters: filters$ })
      : variableSelector$({ skip, limit, filters: filters$ });
  };
  const { data, loading, loadingMore, fetchMore, hasMore, refetch } =
    useInfiniteQuery(query, {
      countSelector,
      variableSelector,
      dataSelector,
      client,
      context,
      fetchPolicy,
      filters,
      initialData,
      limit,
      onCompleted,
      onError,
      skip,
    });

  const options = useMemo(() => {
    let newData = [...data];

    if (extraOptions && extraOptions.length > 0) {
      newData = [...extraOptions, ...newData];
    }

    if (filterOptions) {
      newData = newData.filter(filterOptions);
    }

    return newData;
  }, [data, filterOptions, extraOptions]);

  const debounceRefetch = useMemo(() => debounce(refetch, 500), [refetch]);

  const isLoading = loading || loadingMore || loadingInput;

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { currentTarget } = e;
    onPopupScroll?.(e);
    if (!isLoading && hasMore) {
      if (
        currentTarget.scrollTop + currentTarget.offsetHeight >=
        currentTarget.scrollHeight
      ) {
        fetchMore();
      }
    }
  };

  const handleSearch = (value: string) => {
    onSearch?.(value);
    if (allowSearch) {
      setSearch(value);
      debounceRefetch(({ skip, limit, filters }) =>
        variableSelector$({ skip, limit, search: value, filters }),
      );
    }
  };

  return (
    <Select
      loading={isLoading}
      {...(allowSearch && {
        showSearch: true,
        onSearch: handleSearch,
      })}
      onPopupScroll={handleScroll}
      {...rest}
      options={options}
      filterOption={false}
      labelInValue
    />
  );
};

export default InfiniteSelect;
