import { gql } from '../../../__generated__';

export const ASSESSEE_STATES =
  gql(`query AssesseeStats($where: AssesseeUniqueInput) {
  assesseeStats(where: $where) {
    allNotices
    dueToday
    last24Hours
    openNotices
    oneWeekDue
    overDue
    loginFailed
    totalPan
  }
}`);
