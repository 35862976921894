import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnType, TableProps } from 'antd/es/table';
import dayjs from 'dayjs';
import { omit } from 'lodash';
import { Key, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  DurationType,
  EProceeding,
  EProceedingsFilter,
  EProceedingType,
  InputMaybe,
  SortOrder,
} from '../../__generated__/graphql';
import {
  Export,
  EyeIcon,
  FilePDF,
  Icon,
  Minus,
  Pencil,
} from '../../assets/svg';
import axiosInstance from '../../common/axiosInstance';
import {
  AI_TOOLTIP_MESSAGE,
  defaultDateFormat,
  DURATION_TYPE,
  E_PROCEEDINGS_STATUS,
  E_PROCEEDINGS_TYPE,
  EMPTY_STATE,
  ITR_FILL_START_YEAR,
  LIMIT,
  MESSAGE,
  ROUTES,
} from '../../common/constants';
import {
  downloadCsv,
  formItemProps,
  formValidatorRules,
  generateYearOptions,
} from '../../common/utils';
import CommonPagination from '../../components/CommonPagination';
import CommonRangePicker from '../../components/CommonRangePicker';
import CommonSearch from '../../components/CommonSearch';
import TableComponent from '../../components/CommonTable';
import InfiniteSelect from '../../components/InfiniteSelect';
import CommonModal from '../../components/modals/CommonModal';
import MyBreadcrumb from '../../components/MyBreadcrumb';
import useRouter from '../../hooks/useRouter';
import { FormValues, SelectedRowKeysState } from '../../types/common.type';
import { ASSESSEES_LIST } from '../filed-return/graphql/Queries';
import { UPDATE_E_PROCEEDING_STATUS } from './graphql/Mutation';
import { E_PROCEEDINGS_LIST } from './graphql/Queries';
import StatusTag from '../../components/CommonTag';
const { Text } = Typography;
const { required } = formValidatorRules;

const initialFilters = {
  search: '',
  limit: LIMIT,
};

const initialValue = {
  filters: {
    pan: null,
    assessmentYear: null,
    type: null,
    duration: null,
    period: {
      end: '',
      start: '',
    },
    status: null,
  },
};

const TaxLitigation = () => {
  const [statusForm] = Form?.useForm();
  const [filterForm] = Form?.useForm();
  const { navigate } = useRouter();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState<SelectedRowKeysState>(
    {},
  );
  const [listFilter, setListFilter] = useState(initialFilters);
  const [isClearButtonDisabled, setIsClearButtonDisabled] =
    useState<boolean>(true);
  const filters = Form.useWatch('filters', filterForm) ?? initialValue.filters;
  const totalSelectedRows = Object?.values(selectedRowKeys)?.flat();

  const filter: InputMaybe<EProceedingsFilter> = useMemo(
    () => ({
      assesseeId: filters?.assesseeId?.value,
      assessmentYear: filters?.assessmentYear,
      type: filters?.type,
      ...(filters?.period?.[0] &&
        filters?.period?.[1] && {
          period: {
            start: filters?.period?.[0],
            end: filters?.period?.[1],
          },
        }),
      status: filters?.status,
      skip: (currentPage - 1) * LIMIT,
      search: listFilter.search,
      limit: listFilter.limit,
      duration: filters?.duration,
    }),
    [filters, listFilter, currentPage],
  );

  const {
    data: { eProceedings = {} } = {},
    loading,
    refetch,
  } = useQuery(E_PROCEEDINGS_LIST, {
    variables: {
      filter,
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [updateStatus, { loading: updateStatusLoading }] = useMutation(
    UPDATE_E_PROCEEDING_STATUS,
    {
      onCompleted: () => {
        refetch();
        setSelectedRowKeys({});
        statusForm.resetFields();
        setIsModalOpen(false);
      },
    },
  );

  const handlePagination = (current: number) => {
    setCurrentPage(current);
  };

  const handleClear = () => {
    filterForm?.resetFields();
    setIsClearButtonDisabled(true);
  };

  const rowSelection: TableProps<EProceeding>['rowSelection'] = {
    selectedRowKeys: selectedRowKeys?.[currentPage],
    onChange: (newSelectedRowKeys: Key[]) => {
      setSelectedRowKeys((prev) => ({
        ...prev,
        [currentPage]: newSelectedRowKeys,
      }));
    },
    columnTitle: () =>
      (selectedRowKeys[currentPage]?.length || 0) === 0 ? (
        <span>#</span>
      ) : (
        <span
          className="d-flex pointer"
          onClick={() => {
            setSelectedRowKeys((prev) => ({
              ...prev,
              [currentPage]: [],
            }));
          }}
        >
          <Minus />
        </span>
      ),
  };

  const cancelModal = () => {
    setIsModalOpen(false);
    statusForm.resetFields();
  };

  const handleResponse = (record: EProceeding) => {
    if (record) {
      navigate(`${record?._id}${ROUTES.RESPONSE}`);
    }
  };

  const columns: ColumnType<EProceeding>[] = [
    {
      title: 'PAN',
      dataIndex: 'panNumber',
      key: 'panNumber',
      fixed: 'left',
      render: (_, recode) => {
        return <a className="m-0">{recode?.assessee?.username}</a>;
      },
    },
    {
      title: 'Name of Assessee',
      dataIndex: 'assessee-name',
      key: 'assessee-name',
      fixed: 'left',
      render: (_, recode) => {
        return recode?.assessee?.name;
      },
    },
    {
      title: 'A.Y.',
      dataIndex: 'assessmentYear',
      key: 'noticeId',
      fixed: 'left',
    },
    {
      title: 'F.Y.',
      dataIndex: 'financialYear',
      key: 'financialYear',
    },
    {
      title: 'Reference ID',
      dataIndex: 'noticeReferenceId',
      key: 'noticeReferenceId',
    },
    {
      title: 'Proceedings Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Issued Date',
      dataIndex: 'issuedOn',
      key: 'issuedOn',
      render: (issuedOn) =>
        issuedOn ? dayjs(issuedOn).format(defaultDateFormat) : '-',
    },
    {
      title: 'Response Due Date',
      dataIndex: 'responseDueDate',
      key: 'responseDueDate',
      render: (responseDueDate) =>
        responseDueDate
          ? dayjs(responseDueDate).format(defaultDateFormat)
          : '-',
    },
    {
      title: 'Proceeding Closure Date',
      dataIndex: 'proceedingCloseDate',
      key: 'proceedingCloseDate',
      render: (proceedingClosureDate) =>
        proceedingClosureDate
          ? dayjs(proceedingClosureDate).format(defaultDateFormat)
          : '-',
    },
    {
      title: 'Proceeding Closure Order',
      dataIndex: 'proceedingCloseOrder',
      key: 'proceedingCloseOrder',
      render: (proceedingClosureDate) =>
        proceedingClosureDate
          ? dayjs(proceedingClosureDate).format(defaultDateFormat)
          : '-',
    },
    {
      title: 'Proceeding Limitation Date',
      dataIndex: 'proceedingLimitationDate',
      key: 'proceedingLimitationDate',
      render: (proceedingLimitationDate) =>
        proceedingLimitationDate
          ? dayjs(proceedingLimitationDate).format(defaultDateFormat)
          : '-',
    },
    {
      title: 'Email',
      dataIndex: ['letterPdf', 'from'],
      key: 'email',
    },
    {
      title: 'Notice u/s',
      dataIndex: 'noticeUs',
      key: 'noticeUs',
      ellipsis: true,
    },
    {
      title: 'Document Reference ID',
      dataIndex: 'documentRefId',
      key: 'documentRefId',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },

    {
      title: 'Proceeding Open Date',
      dataIndex: 'proceedingOpenDate',
      key: 'proceedingOpenDate',
      render: (proceedingOpenDate) =>
        proceedingOpenDate
          ? dayjs(proceedingOpenDate).format(defaultDateFormat)
          : '-',
    },
    {
      title: 'Type Of Proceeding',
      dataIndex: 'type',
      key: 'type',
      render: (remarks) => {
        let text = '';
        switch (remarks) {
          case EProceedingType.Fya:
            text = 'For your action';
            break;
          case EProceedingType.Fyi:
            text = 'For your information';
            break;
          default:
            text = '';
        }
        return text;
      },
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      render: (remarks) => (remarks ? remarks : '-'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      fixed: 'right',
      key: 'status',
      render: (status) => <StatusTag status={status} />,
    },
    {
      title: 'Action',
      fixed: 'right',
      render: (record: EProceeding) => (
        <Space>
          <Link
            className="pointer"
            to={record?.letterPdf?.attachments?.[0]?.url ?? ''}
            download={record?.letterPdf?.attachments?.[0]?.url}
            target="_blank"
          >
            <FilePDF />
          </Link>
          <span
            key="edit"
            onClick={() => {
              setIsModalOpen(true);
              statusForm.setFieldsValue({
                statusForm: {
                  status: record.status,
                  remark: record.remarks,
                  id: [record._id],
                },
              });
            }}
            className="pointer"
          >
            <Pencil />
          </span>
          <span onClick={() => handleResponse(record)} className="pointer">
            <EyeIcon key="view" />
          </span>
          <Tooltip placement="top" title={AI_TOOLTIP_MESSAGE}>
            <Button
              type="default"
              icon={<Icon />}
              className="d-flex align-center ask-ai-btn pointer"
            >
              Ask AI
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleFormValuesChange = (
    _changedValues: string,
    allValues: string,
  ) => {
    const hasSelection = Object.values(allValues).some(
      (value) => value !== undefined && value !== null,
    );
    setCurrentPage(1);
    setIsClearButtonDisabled(!hasSelection);
  };

  const exportCsv = async () => {
    const response = await axiosInstance.post('/v1/eproceeding/export-csv', {
      filter: {
        ...omit(filter, ['limit', 'skip']),
      },
    });
    downloadCsv(response);
  };

  const updateStateForm = (value: FormValues) => {
    updateStatus({
      variables: {
        where: {
          ids: value?.statusForm?.id,
        },
        data: {
          remarkByAdmin: value?.statusForm?.remark,
          status: value?.statusForm?.status,
        },
      },
    });
  };

  return (
    <div className="container">
      <div className="mt-16 mb-16">
        <MyBreadcrumb />
      </div>
      <div className="d-flex justify-between align-center">
        <Text className="heading">Income Tax Notice</Text>
        <CommonSearch
          handleChangeSearch={(val) => {
            setListFilter((prev) => ({ ...prev, search: val }));
            setCurrentPage(1);
          }}
          iconPlacement="right"
          allowClear
        />
      </div>
      <div className="d-flex mt-16 gap-16">
        <Button
          type="primary"
          className="d-flex align-center"
          icon={<Export />}
          onClick={() => exportCsv()}
        >
          Export To CSV
        </Button>
        <Button
          type="default"
          className="d-flex align-center"
          onClick={async () => {
            setIsModalOpen(true);
            statusForm.setFieldsValue({
              statusForm: {
                id: totalSelectedRows,
              },
            });
          }}
          disabled={!(totalSelectedRows.length > 0)}
        >
          Bulk Update Status
        </Button>
      </div>
      <Card className="mt-16">
        <Form
          form={filterForm}
          layout="vertical"
          onValuesChange={handleFormValuesChange}
        >
          <div className="filters d-flex align-center gap-16">
            <Form.Item
              name={['filters', 'assesseeId']}
              label="Select Client"
              className="select"
            >
              <InfiniteSelect
                query={ASSESSEES_LIST}
                variableSelector={({ skip, limit, search }) => ({
                  filter: {
                    skip,
                    limit,
                    search,
                  },
                  sort: {
                    sortBy: SortOrder.Desc,
                  },
                })}
                dataSelector={({ assessees }) => {
                  return (
                    assessees?.data?.map((item) => ({
                      label: item?.username ?? '',
                      value: item?._id ?? '',
                    })) ?? []
                  );
                }}
                countSelector={({ assessees }) => assessees?.count ?? 0}
                allowSearch
                placeholder="PAN, username"
                allowClear
              />
            </Form.Item>
            <Form.Item
              name={['filters', 'assessmentYear']}
              label="Assessment Year"
              className="select"
            >
              <Select
                placeholder="Select"
                options={generateYearOptions(ITR_FILL_START_YEAR)}
                allowClear
                showSearch
              />
            </Form.Item>
            <Form.Item
              name={['filters', 'type']}
              label="Type Of Proceeding"
              className="select"
            >
              <Select
                placeholder="Select"
                options={E_PROCEEDINGS_TYPE}
                allowClear
              />
            </Form.Item>
            <Form.Item
              name={['filters', 'status']}
              label="Status"
              className="select"
            >
              <Select
                placeholder="Select"
                options={E_PROCEEDINGS_STATUS}
                allowClear
              />
            </Form.Item>
            <Form.Item
              name={['filters', 'duration']}
              label="Duration"
              className="select"
            >
              <Select placeholder="Select" options={DURATION_TYPE} allowClear />
            </Form.Item>
            {filter?.duration === DurationType.Custom && (
              <Form.Item
                name={['filters', 'period']}
                label="Period"
                className="select"
              >
                <CommonRangePicker />
              </Form.Item>
            )}
            <Form.Item className="d-flex align-end align-self-end">
              <Button
                type="link"
                onClick={handleClear}
                disabled={isClearButtonDisabled}
              >
                Clear All
              </Button>
            </Form.Item>
          </div>
        </Form>
        <TableComponent<EProceeding>
          columns={columns}
          dataSource={eProceedings?.data as EProceeding[]}
          pagination={false}
          rowSelection={rowSelection}
          rowKey="_id"
          scroll={{ x: 'max-content' }}
          locale={EMPTY_STATE}
          loading={loading}
        />
        <CommonPagination
          count={eProceedings?.count ?? 0}
          handlePagination={handlePagination}
          currentPage={currentPage}
        />
      </Card>
      <CommonModal
        open={isModalOpen}
        title="Update Status"
        footer={false}
        closable={true}
        onCancel={cancelModal}
        maskClosable={false}
      >
        <div className="create-forms-form">
          <Form
            onFinish={updateStateForm}
            form={statusForm}
            layout="vertical"
            preserve={false}
          >
            <Form.Item
              label="Status"
              name={['statusForm', 'status']}
              rules={[{ ...required, message: MESSAGE?.required }]}
              normalize={formItemProps.normalize}
            >
              <Select
                options={E_PROCEEDINGS_STATUS}
                placeholder="Change Status"
              />
            </Form.Item>
            <Form.Item
              label="Remark"
              name={['statusForm', 'remark']}
              normalize={formItemProps.normalize}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Remark"
              name={['statusForm', 'id']}
              normalize={formItemProps.normalize}
              hidden
            >
              <Select mode="multiple" />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="full-width"
              loading={updateStatusLoading}
            >
              Update
            </Button>
          </Form>
        </div>
      </CommonModal>
    </div>
  );
};

export default TaxLitigation;
