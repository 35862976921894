import { useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Typography,
} from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import {
  ActiveBox,
  Calendar,
  CloseNotification,
  FilePDF,
  Files,
  HourGlassLaw,
  Pen,
  Pencil,
  RegularCalendar,
  Users,
  Warning,
} from '../../assets/svg';
import {
  defaultDateFormat,
  EMPTY_STATE,
  GUTTER_VARIATIONS,
  ZERO_PLACEHOLDER_TEXT,
} from '../../common/constants';
import TableComponent from '../../components/CommonTable';
import LoaderComponent from '../../components/LoaderComponent';
import StatisticCard from './component/StatiscticCard';
import './dashboard.less';
import { ASSESSEE_STATES } from './graphql/Queries';
const { RangePicker } = DatePicker;
const { Text } = Typography;

type NearingDueDates = {
  key: React.Key;
  pan: string;
  Name: string;
  referenceId: string;
  ay: string;
  fy: string;
  dueDate: string;
};

const Dashboard = () => {
  const [form] = Form.useForm();
  const [clientReady, setClientReady] = useState<boolean>(false);
  const [synced, setSynced] = useState<boolean>(true);

  const { data: { assesseeStats } = {}, loading: loadStats } = useQuery(
    ASSESSEE_STATES,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    setClientReady(true);
    setSynced(false);
  }, []);

  const columns: ColumnType<NearingDueDates>[] = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      fixed: 'left',
    },
    {
      title: 'PAN',
      dataIndex: 'pan',
      key: 'pan',
      fixed: 'left',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Name of Assessee',
      dataIndex: 'Name',
      key: 'Name',
      render: (text) => text ?? '-',
    },
    {
      title: 'Reference ID',
      dataIndex: 'referenceId',
      key: 'referenceId',
      render: (text) => text ?? '-',
    },
    {
      title: 'Assessment Year',
      dataIndex: 'ay',
      key: 'ay',
      render: (text) => text ?? '-',
    },
    {
      title: 'Financial Year',
      dataIndex: 'fy',
      key: 'fy',
      render: (text) => text ?? '-',
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (date: string) => dayjs(date).format(defaultDateFormat),
    },
    {
      title: 'Action',
      fixed: 'right',
      render: () => (
        <Space>
          <FilePDF key="view" />
          <Pencil key="edit" />
        </Space>
      ),
    },
  ];
  const data: NearingDueDates[] = [
    {
      key: '1',
      pan: 'ABCDE1234F',
      Name: 'John Doe',
      referenceId: 'REF123456',
      ay: '2023-24',
      fy: '2022-23',
      dueDate: '2024-09-30',
    },
    {
      key: '2',
      pan: 'XYZAB5678C',
      Name: 'Jane Smith',
      referenceId: 'REF789012',
      ay: '2022-23',
      fy: '2021-22',
      dueDate: '2024-10-15',
    },
    {
      key: '3',
      pan: 'LMNOP9876Q',
      Name: 'Alice Johnson',
      referenceId: 'REF345678',
      ay: '2024-25',
      fy: '2023-24',
      dueDate: '2024-11-01',
    },
    {
      key: '4',
      pan: 'QRSTU5432V',
      Name: 'Bob Brown',
      referenceId: 'REF901234',
      ay: '2023-24',
      fy: '2022-23',
      dueDate: '2024-12-20',
    },
    {
      key: '5',
      pan: 'FGHIJ8765K',
      Name: 'Emily Davis',
      referenceId: 'REF567890',
      ay: '2022-23',
      fy: '2021-22',
      dueDate: '2024-09-25',
    },
  ];

  return (
    <LoaderComponent spinning={loadStats}>
      <div className=" plan-notification">
        <Text>
          Hey! Your “Free Trial” package has 2 free clients.
          <span>Get more clients</span>
        </Text>
        <CloseNotification />
      </div>
      <div className="dashboard-content-holder relative">
        <div className="container">
          <div className="dashboard-content-wrapper mt-32 mb-32">
            <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}>
              <Col md={6} lg={6} className="section-1">
                <Card className="mr-24">
                  <div className="d-flex justify-between mb-4">
                    <Text>Range</Text>
                  </div>
                  <RangePicker
                    className="full-width"
                    placeholder={['From', 'To']}
                  />
                  <Card className="mt-8">
                    <div className="calendar-height">Calender</div>
                  </Card>
                  <Card className="mt-16 mb-16">
                    <Row className="d-flex  align-self-stretch flex-direction-column gap-16">
                      <Col className="d-flex flex-wrap align-center">
                        <div className="color-box bg-blue" />
                        <span className="ml-10 color-blue">
                          2 notice issued
                        </span>
                      </Col>
                      <Col className="d-flex flex-wrap align-center">
                        <div className="color-box bg-red" />
                        <span className="ml-10 color-red">1 notice due</span>
                      </Col>
                      <Col className="d-flex flex-wrap align-center">
                        <div className="color-box bg-orange" />
                        <span className="ml-10 color-orange">
                          2 notice issues and 1 due
                        </span>
                      </Col>
                    </Row>
                  </Card>
                  <div className="sync-calendar">
                    <Text>
                      {synced ? 'Synced' : 'Sync'} Google/Outlook Calendar
                    </Text>
                    <Form form={form} className="d-flex full-width mt-2">
                      <Form.Item className="mr-8 mb-0">
                        <Input placeholder="Doe@example.com" name="email" />
                      </Form.Item>
                      <Form.Item className="mb-0">
                        {!synced ? (
                          <Button
                            type="primary"
                            disabled={
                              !clientReady ||
                              !form.isFieldsTouched(true) ||
                              !!form
                                .getFieldsError()
                                .filter(({ errors }) => errors.length).length
                            }
                          >
                            Sync
                          </Button>
                        ) : (
                          <Button
                            type="link"
                            disabled={
                              !clientReady ||
                              !form.isFieldsTouched(true) ||
                              !!form
                                .getFieldsError()
                                .filter(({ errors }) => errors.length).length
                            }
                          >
                            Desync
                          </Button>
                        )}
                      </Form.Item>
                    </Form>
                  </div>
                </Card>
              </Col>
              <Col md={18} lg={18}>
                <Card className="mb-16">
                  <div>
                    <div className="statistic-cards">
                      <StatisticCard
                        icon={<Pen />}
                        label="All Notices"
                        value={
                          assesseeStats?.allNotices || ZERO_PLACEHOLDER_TEXT
                        }
                      />
                      <StatisticCard
                        icon={<Files />}
                        label="Open Notices"
                        value={
                          assesseeStats?.openNotices || ZERO_PLACEHOLDER_TEXT
                        }
                      />
                      <StatisticCard
                        icon={<Calendar />}
                        label="Due Today"
                        value={assesseeStats?.dueToday || ZERO_PLACEHOLDER_TEXT}
                      />
                      <StatisticCard
                        icon={<ActiveBox />}
                        label="7 Days Due"
                        value={
                          assesseeStats?.oneWeekDue || ZERO_PLACEHOLDER_TEXT
                        }
                      />
                    </div>
                    <Divider className="m-16" />
                    <div className="statistic-cards">
                      <StatisticCard
                        icon={<HourGlassLaw />}
                        label="Last 24 Hours"
                        value={
                          assesseeStats?.last24Hours || ZERO_PLACEHOLDER_TEXT
                        }
                      />
                      <StatisticCard
                        icon={<RegularCalendar />}
                        label="OverDue"
                        value={assesseeStats?.overDue || ZERO_PLACEHOLDER_TEXT}
                      />
                      <StatisticCard
                        icon={<Users />}
                        label="Total PAN"
                        value={assesseeStats?.totalPan || ZERO_PLACEHOLDER_TEXT}
                      />
                      <StatisticCard
                        icon={<Warning />}
                        label="Failed Login"
                        value={
                          assesseeStats?.loginFailed || ZERO_PLACEHOLDER_TEXT
                        }
                      />
                    </div>
                  </div>
                </Card>
                <div className="mt-32">
                  <Text className="head">Nearing Due Dates</Text>
                  <TableComponent<NearingDueDates>
                    columns={columns}
                    dataSource={data}
                    className="notice-table mt-8"
                    scroll={{ x: 'max-content' }}
                    locale={EMPTY_STATE}
                  />
                  <Button type="link" className="underline-btn float-right">
                    View All
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </LoaderComponent>
  );
};

export default Dashboard;
