import { gql as apolloGql, TypedDocumentNode } from '@apollo/client';

export const FETCH_USER_QUERY = apolloGql`query CurrentUser {
  currentUser {
    id
    firstName
    lastName
    profileImage
  }
}` as TypedDocumentNode<{
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'UserData';
    id?: string | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    profileImage?: string | null;
  } | null;
}>;
