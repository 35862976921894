import { Button, Pagination, Typography } from 'antd';
import { PaginationConfig } from 'antd/es/pagination';
import { CaretLeftRegular, CaretRight } from '../assets/svg';
import { LIMIT } from '../common/constants';
const { Text } = Typography;

type CommonPaginationPropType = {
  count: number;
  pageSize?: number;
  handlePagination: (current: number) => void;
  currentPage?: number;
  sizeChanger?: boolean;
};

function CommonPagination({
  count,
  pageSize = LIMIT,
  currentPage = 1,
  handlePagination,
  sizeChanger = false,
}: CommonPaginationPropType) {
  const itemRender: PaginationConfig['itemRender'] = (_current, type) => {
    if (type === 'prev') {
      return (
        <Button
          type="default"
          className="justify-center d-flex align-center "
          icon={<CaretLeftRegular />}
        />
      );
    }
    if (type === 'next') {
      return (
        <Button
          type="default"
          className="justify-center d-flex align-center"
          icon={<CaretRight />}
        />
      );
    }
    return null;
  };

  return (
    <div className="custom-pagination-wrapper mt-16 gap-32">
      <div>
        <Text>
          Page {currentPage} / {Math.ceil(count / pageSize)}
        </Text>
      </div>
      <div>
        <Pagination
          defaultCurrent={currentPage}
          current={currentPage}
          total={count}
          pageSize={pageSize}
          itemRender={itemRender}
          onChange={handlePagination}
          showSizeChanger={sizeChanger}
          showLessItems
        />
      </div>
    </div>
  );
}

export default CommonPagination;
