import { gql } from '../../../__generated__';

const ITR_FILED_RETURN_LIST = gql(`query ItrReturnList($filter: ItrListFilter) {
    itrList(filter: $filter) {
      data {
        assessmentYear
        filingType
        acknowledgmentNo
        filedBy
        filingDate
        _id
        assessee {
          username
        }
      }
      count
    }
  }`);

const ASSESSEES_LIST =
  gql(`query SelectAssessees($filter: AssesseesFilter, $sort: [AssesseesSort]!) {
  assessees(filter: $filter, sort: $sort) {
    data {
      username
      _id
      name
    }
    count
  }
}`);

const ITR_STATUS_LIST = gql(`query Itr($where: ItrUniqueInput!) {
  itr(where: $where) {
    _id
    assessmentYear
    filingType
    acknowledgmentNo
    filedBy
    itrType
    filingDate
    attachments {
      name
      url
    }
    metadata
    createdAt
    updatedAt
    assessee {
      _id
      username
      name
    }
  }
}`);

export { ASSESSEES_LIST, ITR_FILED_RETURN_LIST, ITR_STATUS_LIST };
