export const themeConfig = {
  token: {
    colorPrimary: '#7a2976',
    radiusBase: '4px',
    controlRadius: '4px',
    colorLink: 'var(--primary-Main)',
    colorLinkActive: 'var(--primary-Main)',
    colorLinkHover: 'var(--primary-Main)',
    colorBgLayout: 'var(--background-2)',
    fontFamily: 'var(--font-family)',
  },
  components: {
    Typography: {},
    Button: {
      borderRadius: 8,
      colorPrimary: 'var(--primary-Main)',
      colorPrimaryHover: 'var(--primary-Main)',
      colorPrimaryActive: 'var(--primary-Main)',
      colorLink: 'var(--primary-Main)',
      colorLinkHover: 'var(--primary-Main)',
      colorLinkActive: 'var(--primary-Main)',
      defaultColor: 'var(--primary-Main)',
      defaultBorderColor: 'var(--primary-Main)',
      primaryShadow: 'unset',
      colorTextDisabled: '#F4F4F4',
      colorBgContainerDisabled: 'var(--primary-Main)',
      borderColorDisabled: 'var(--primary-Main)',
      colorText: 'var(--primary)',
      controlHeight: 40,
      fontSize: 16,
    },
    Menu: { itemSelectedBg: 'var(--primary-Tint-02)' },
    Table: {
      headerBg: 'var(--color-grey-light)',
      headerColor: 'var(--text-medium)',
      fontWeightStrong: 500,
      rowSelectedBg: '#FBF9FB',
      rowSelectedHoverBg: '#FBF9FB',
    },
    Form: {
      labelFontSize: 16,
      itemMarginBottom: 16,
    },
    Breadcrumb: {
      fontSize: 12,
    },
    Input: {
      activeShadow: 'unset',
      controlHeight: 40,
      colorTextPlaceholder: 'var(--text-medium)',
    },
    Select: {
      controlOutlineWidth: 0,
      optionSelectedBg: '#FBF9FB',
      controlHeight: 40,
    },
    DatePicker: {
      activeShadow: 'unset',
      controlHeight: 40,
    },
    Tag: {
      lineWidth: 0,
    },
    Badge: {
      fontSize: 12,
    },
    Modal: {
      colorBgMask: 'rgba(0, 0, 0, 0.70)',
      titleFontSize: 20,
    },
    Checkbox: {
      colorPrimary: 'var(--text-medium)',
      colorPrimaryHover: 'var(--text-medium)',
      colorPrimaryBorder: 'var(--text-medium)',
    },
  },
};
