import { DatePicker, TimeRangePickerProps } from 'antd';
import { DEFUALT_PRESETS } from '../common/constants';

const { RangePicker } = DatePicker;

const CommonRangePicker = ({
  range = DEFUALT_PRESETS,
  ...rest
}: {
  range?: TimeRangePickerProps['presets'];
}) => {
  return <RangePicker presets={range} {...rest} allowClear />;
};

export default CommonRangePicker;
