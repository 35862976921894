import React from 'react';
import { Space, Typography } from 'antd';
import { StatisticCardProps } from '../graphql/clients.type';

const { Text } = Typography;

const StatisticCard: React.FC<StatisticCardProps> = ({
  icon,
  label,
  value,
  onClick,
}) => {
  return (
    <Space className="statistic pointer" onClick={onClick}>
      <div className="icon">{icon}</div>
      <div className="status">
        <Text>{label}</Text>
        <Text className="value">{value}</Text>
      </div>
    </Space>
  );
};

export default StatisticCard;
