import { Navigate, Outlet } from 'react-router-dom';
import { ROUTES } from './common/constants';

function PublicRoute() {
  const idToken = false;

  return idToken ? <Navigate to={ROUTES.MAIN} /> : <Outlet />;
}

export default PublicRoute;
