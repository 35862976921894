import { Modal } from 'antd';

type ModalType = {
  title?: string;
  open: boolean;
  okText?: string;
  cancelText?: string;
  onOk?: () => void;
  onCancel?: () => void;
  closable?: boolean;
  children: React.ReactNode;
  maskClosable?: boolean;
  centered?: boolean;
  className?: string;
  footer?: boolean;
};
const CommonModal = (props: ModalType) => {
  const {
    open = false,
    onOk,
    onCancel,
    title,
    children,
    okText = 'OK',
    cancelText = 'NO',
    closable = true,
    maskClosable = true,
    centered = true,
    className,
    ...rest
  } = props;
  return (
    <Modal
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      title={title}
      okText={okText}
      cancelText={cancelText}
      destroyOnClose
      closable={closable}
      maskClosable={maskClosable}
      centered={centered}
      className={className}
      {...rest}
    >
      {children}
    </Modal>
  );
};

export default CommonModal;
